import React from 'react';
import Hero1 from '../../assets/hi1.png';
import Hero2 from '../../assets/hi2.png';

const IntroSection = () => {
  return (
    <section className="flex flex-col md:flex-row items-center gap-20 justify-between p-12 px-4 sm:px-6 md:p-12 md:px-8 lg:px-16 w-full">
      
      {/* Image Section */}
      <div className="flex space-x-2 w-full md:w-1/2 mb-6 md:mb-0">
        <div className="w-1/2">
          <img 
            src={Hero1} 
            alt="Salad" 
            className="rounded-lg w-full h-auto object-cover sm:max-h-32 md:max-h-56 lg:max-h-[400px] transition ease-out delay-150 hover:-translate-x hover:scale-110 duration-300" 
          />
        </div>
        <div className="w-1/2">
          <img 
            src={Hero2} 
            alt="Diced Vegetables" 
            className="rounded-lg w-full h-auto object-cover sm:max-h-32 md:max-h-56 lg:max-h-[400px] transition ease-out delay-150 hover:-translate-x hover:scale-110 duration-300" 
          />
        </div>
      </div>

      {/* Text Section */}
      <div className="w-full md:w-1/2 text-center md:text-left">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
          Experience World Class Cuisine
        </h2>
        <p className="text-gray-600 text-sm sm:text-base md:text-lg mb-6">
          We are all about creating unforgettable dining experiences. Led by Chef Sey Mour, our team specializes in crafting delicious dishes using fresh, local ingredients. Whether it's a wedding, corporate event, or intimate dinner, we're here to make your event special. Explore our menu and let's create something extraordinary together.
        </p>
        <button className="px-6 py-2 border border-black rounded-md font-semibold hover:bg-gray-200 transition duration-200">
          ABOUT US
        </button>
      </div>
    </section>
  );
};

export default IntroSection;
