import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import About from './pages/About/About';
import Blog from './pages/Blog/Blog';
import Contact from './pages/Contact/Contact';
import Event from './pages/Event/Event';
import Gallery from './pages/Gallery/Gallery';
import Home from './pages/Home/Home';
import Menu from './pages/Menu/Menu';
import NotFound from './pages/NotFound/NotFound'; // Import the NotFound component
import ScrollToTop from './components/Navbar/ScrollToTop';
import ScrollToTopButton from './components/Navbar/ScrollToTopButton';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <ScrollToTop />
      <ScrollToTopButton/>
      {/* <Navbar /> */}
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/events" element={<Event />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
