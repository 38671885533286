import React from "react";
import { PlayCircleOutline } from "@mui/icons-material";
import Event1 from "../../assets/event1.png";
import Event2 from "../../assets/event2.png";
import Event3 from "../../assets/event3.png";
import Play from "../../assets/play.png";

const EventGallery = () => {
  const eventSections = [
    {
      title: "Private Dining",
      images: [Event1, Event2, Event3, Event3, Event3],
    },
    {
      title: "Yacht Dining",
      images: [Event1, Event2, Event3, Event3, Event3],
    },
    {
      title: "Live BBQ Party",
      images: [Event3, Event3, Event3, Event3, Event3],
    },
  ];

  return (
    <div className="px-4 sm:px-8 lg:px-16 py-8">
      {eventSections.map((section, index) => (
        <div key={index} className="my-8">
          {/* Section Title */}
          <h3 className="text-center text-xl font-semibold mb-4">
            {section.title}
          </h3>

          {/* Image Grid Layout */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 my-10 md:gap-6">
            {/* Top Row (2 or 3 Images) */}
            {section.images.slice(0, 3).map((image, idx) => (
              <div key={idx} className="relative group">
                <img
                  src={image}
                  alt={`${section.title} Image ${idx + 1}`}
                  className="w-full h-[300px] object-cover rounded-md"
                />
                {/* Play Icon Overlay */}
                <div className="absolute inset-0 flex items-center justify-center">
                  <img
                    src={Play}
                    alt="Play Icon"
                    className="w-12 sm:w-16 md:w-20 lg:w-24 xl:w-32 2xl:w-40"
                  />
                </div>
              </div>
            ))}
          </div>

          {/* Bottom Row (3 Images, Hidden on Mobile) */}
          <div className="grid grid-cols-3 gap-4 mt-4 md:gap-6 hidden sm:grid">
            {section.images.slice(2, 5).map((image, idx) => (
              <div key={idx + 2} className="relative group">
                <img
                  src={image}
                  alt={`${section.title} Image ${idx + 3}`}
                  className="w-full h-auto object-cover rounded-md"
                />
                {/* Play Icon Overlay */}
                {/* <div className="absolute inset-0 flex items-center justify-center ">
                  <img
                    src={Play}
                    alt="Play Icon"
                    className="w-12 sm:w-16 md:w-20 lg:w-24 xl:w-32 2xl:w-40"
                  />
                </div> */}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default EventGallery;
