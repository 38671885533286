import React from "react";

const services = [
  "FINE DINING",
  "OUTDOOR CATERING",
  "DOOR DELIVERY",
  "BANQUET",
];

const Services = () => {
  return (
    <section className="bg-white py-4 h-32 flex justify-center items-center border-4 border-black overflow-hidden mt-8">
      <div className="services-container flex space-x-12 animate-slide">
        {services.map((service, index) => (
          <div key={index} className="text-black text-2xl md:text-4xl font-bold flex items-center">
            {service}
            {index < services.length && (
              <span className="text-yellow-500 mx-4 text-3xl md:text-5xl">★</span>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
