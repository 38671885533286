// HeroSection.js
import React from "react";
import Navbar from "../Navbar/Navbar";
import BackgroundImage from "../../assets/menuB.png";

const MenuHero = () => {
  return (
    <div
      className="relative h-96 bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
        <Navbar/>
      <div className="flex flex-col items-center justify-center h-full text-center px-4 ">
        <h1
          className="text-white leading-tight"
          style={{
            fontFamily: "Bebas Neue, sans-serif",
          }}
        >
          <span className="text-4xl sm:text-5xl md:text-6xl font-caramel lg:text-7xl xl:text-8xl">
            Menu
          </span>
          <br />
        </h1>


      </div>
    </div>
  );
};

export default MenuHero;
