// HeroSection.js
import React from "react";
import Navbar from "../Navbar/Navbar";
import BackgroundImage from "../../assets/heroimage.png";

const Hero = () => {
  return (
    <div
      className="relative h-screen bg-cover bg-center text-white"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      <Navbar />
      <div className="flex flex-col items-center justify-center h-full text-center px-4 ">
        <h1
          className="text-yellow-500 font-bold leading-tight"
          style={{
            fontFamily: "Bebas Neue, sans-serif",
          }}
        >
          <span className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl">
            SEASONED BY
          </span>
          <br />
          <span
            className="block text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl pl-4 sm:pl-8 md:pl-16 lg:pl-24 xl:pl-40"
            // Responsive padding left using Tailwind classes
            style={{ paddingTop: "0.5rem" }}
            // className="pl-4 sm:pl-8 md:pl-16 lg:pl-24 xl:pl-40"
          >
            SEYMOUR
          </span>
        </h1>

        <p className="text-xs font-lato sm:text-sm md:text-base lg:text-lg xl:text-xl mt-4 pl-4 sm:pl-8 md:pl-16 lg:pl-24 xl:pl-40">
          Clean Eating Can Still Be Good!!
        </p>

        <button className="mt-12 bg-yellow-500 text-2xl text-white h-[60px] py-2 px-4 sm:px-8 lg:px-12 rounded transition-transform duration-300 hover:bg-yellow-600 hover:scale-105 hover:shadow-lg">
          Order Now
        </button>
      </div>
    </div>
  );
};

export default Hero;
