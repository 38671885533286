import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from 'emailjs-com';

const SubscribeSection = () => {
  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const SERVICE_ID = 'service_wexudd7';
      const TEMPLATE_ID = 'template_jccjfcr';
      const PUBLIC_KEY = '2u9L4g-ooBN190S9E';

     
      await emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, PUBLIC_KEY);

      toast.success('Message sent successfully!');
      setFormData({ email: ''}); 
    } catch (error) {
      console.error('Error sending email:', error);
      toast.error('Failed to send message. Please try again.');
    }
  };

  return (
    <section className="bg-gray-100 py-12 px-4 sm:px-8 md:px-16 flex flex-col md:flex-row justify-between">
      {/* Left Side: Text Content */}
      <div className="md:w-1/3 mb-8 md:mb-0  md:text-left">
        <h2 className="text-yellow-500 text-2xl sm:text-3xl md:text-4xl font-bold leading-tight font-lato">
          KEEP UP TO DATE WITH SEASONED BY SEYMOUR CATERING
        </h2>
        <p className="mt-4 text-gray-700 text-sm sm:text-base md:text-lg font-lato">
          Be the first to signup and receive special news from seasoned by Sey
          Mour Catering.
        </p>
      </div>

      {/* Right Side: Subscription Form */}
      <div className="md:w-1/2 flex flex-col items-start gap-3 justify-center w-full">
        {/* Form wrapper */}
        <form onSubmit={handleSubmit} className="w-full">
          <input
            type="email"
            name="email"
            placeholder="Email Address..."
            value={formData.email}
            onChange={handleChange}
            required
            className="p-3 text-sm sm:text-base md:text-lg border border-gray-300 rounded mb-4 sm:mb-0 w-full sm:w-full focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
          <button
            type="submit"
            className="px-4 py-2 mt-3 sm:px-6 sm:py-3 sm:w-52 border border-gray-900 text-gray-900 text-sm font-lato sm:text-base md:text-lg font-semibold hover:bg-yellow-500 hover:text-white transition hover:border-yellow-500"
          >
            SUBSCRIBE
          </button>
        </form>
      </div>
    </section>
  );
};

export default SubscribeSection;
