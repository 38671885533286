import React from "react";
import Slider from "react-slick";
import Image1 from "../../assets/test1.png";
import Image2 from "../../assets/test2.png";
import Image3 from "../../assets/test3.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: "Emily K.",
      feedback: "Absolutely loved the food and service provided by Seasoned By Sey Mour! Our guests couldn't stop raving about the delicious dishes.",
      image: Image1,
    },
    {
      name: "Michael T.",
      feedback: "Chef Sey Mour and his team exceeded our expectations. Their attention to detail and dedication to excellence made our event truly memorable.",
      image: Image2,
    },
    {
      name: "Sophia L.",
      feedback: "From start to finish, Seasoned By Sey Mour Catering delivered exceptional service and top-notch cuisine. Highly recommend!",
      image: Image3,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // Tablet and up (e.g., 1024px)
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768, // Mobile devices (e.g., 768px)
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="md:p-12 text-center my-10 mx-10">
      <h2 className="text-2xl font-semibold mb-12">⭐ What Customers Are Saying About Us ⭐</h2>
      <div className="w-full max-w-1xl mx-auto">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="w-20 h-20 rounded-full overflow-hidden mx-auto mb-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <p className="text-gray-600 italic mb-2">"{testimonial.feedback}"</p>
              <h3 className="text-lg font-semibold">{testimonial.name}</h3>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialsSection;
