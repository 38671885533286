import React from "react";

import Event1 from "../../assets/gal1.png";
import Event2 from "../../assets/gal2.png";
import Event3 from "../../assets/gal3.png";
import Event4 from "../../assets/gal4.png";
import Event5 from "../../assets/gal5.png";
import Event6 from "../../assets/gal6.png";
import Event7 from "../../assets/gal7.png";
import Event8 from "../../assets/gal8.png";
import Play from "../../assets/play.png";
// import Event9 from "../../assets/gal9.png";

// Array of image URLs to be used in the grid.
const images = [
  Event1,
  Event2,
  Event3,
  Event4,
  Event5,
  Event6,
  Event7,
  Event8,
  // Event9
];

const GalleryGrid = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-20 p-4 max-w-screen-lg mx-auto">
      {/* First row */}
      <img
        src={images[0]}
        alt="Food 1"
        className="w-full h-full object-cover rounded-lg transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
      />
      <img
        src={images[1]}
        alt="Food 2"
        className="w-full h-full object-cover rounded-lg transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
      />
      <img
        src={images[2]}
        alt="Food 3"
        className="w-full h-full object-cover rounded-lg transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
      />

      {/* Second row with larger center image */}
      <img
        src={images[3]}
        alt="Food 4"
        className="w-full h-full object-cover rounded-lg transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
      />
      <div className="relative rounded-lg overflow-hidden">
        <img
          src={images[4]}
          alt="Food 5"
          className="w-full h-full object-cover transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none "
        />
        <div className="absolute inset-0 flex items-center justify-center">
          <img
            src={Play}
            alt="Play Icon"
            className="w-12 sm:w-16 md:w-20 lg:w-24 xl:w-32 2xl:w-40 transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
          />
        </div>
      </div>
      <img
        src={images[5]}
        alt="Food 6"
        className="w-full h-full object-cover rounded-lg transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
      />

      {/* Third row */}
      <img
        src={images[6]}
        alt="Food 7"
        className="w-full h-full object-cover rounded-lg transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
      />
      <img
        src={images[7]}
        alt="Food 8"
        className="w-full h-full object-cover rounded-lg transition transform hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none"
      />
      {/* Uncomment the line below if you want to add the ninth image */}
      {/* <img src={images[8]} alt="Food 9" className="w-full h-full object-cover rounded-lg" /> */}
    </div>
  );
};

export default GalleryGrid;
